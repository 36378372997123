import React from "react";
import { useState, useEffect } from "react";
import AnimatedLetters from "../AnimatedLetters";

const Blog = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  const nameArray = ["B", "l", "o", "g"];

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 1700);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="container">
      <h1>
        <AnimatedLetters
          letterClass={letterClass}
          strArray={nameArray}
          idx={10}
        />
      </h1>
    </div>
  );
};

export default Blog;
