import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Socials = () => {
  return (
    <div className="container" style={{ padding: "3rem" }}>
      <div className="row justify-content-center">
        <div className="col-4 fa-2x" align="center">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://ca.linkedin.com/in/varun-senguttuvan"
          >
            <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
          </a>
        </div>
        <div className="col-4 fa-2x" align="center">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/varunseng"
          >
            <FontAwesomeIcon icon={faTwitter} color="#4d4d4e" />
          </a>
        </div>
        <div className="col-4 fa-2x" align="center">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/varunseng/"
          >
            <FontAwesomeIcon icon={faInstagram} color="#4d4d4e" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Socials;
