import "./index.css";
import React from "react";
import MyNavbar from "../MyNavbar";
// import Main from '../Main'
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="page">
      <MyNavbar />
      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
