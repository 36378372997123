import "./index.css";
import React from "react";
import Socials from "../Socials";
import AnimatedLetters from "../AnimatedLetters";
import MyCloud from "../MyCloud";
import { useState, useEffect } from "react";

const About = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  const nameArray = ["A", "b", "o", "u", "t", " ", "M", "e"];

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 2100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      <div className="container">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={nameArray}
            idx={10}
          />
        </h1>
        <div id="aboutMe" className="row">
          <div className="col-md-6 col-sm-12" style={{ marginTop: "50px" }}>
            <p>
              I am a software engineer based out of Toronto, Canada. I like to
              build tools that help solve problems.
            </p>
            <br />
            <p>
              I graduated from the University of Waterloo with a degree in
              Software Engineering in 2016. I have been programming since I was
              13, and I love dabbling with new tools and technologies--I try to learn something new everyday.
            </p>
            <br />
            <p>
              When I am not programming, I like to watch old movies or read sci-fi/mystery novels.
              Solving puzzles (programming or otherwise!) is also a big hobby of mine.
            </p>
            <Socials />
          </div>
          <div className="col-md-6 col-sm-12 align-self-center text-center">
            <div style={{ overflow: "hidden" }}>
              <MyCloud />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
