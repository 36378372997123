import "./index.css";
import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";

function MyNavbar() {
  return (
    <Navbar id="myNavbar" sticky="top" expand="lg" className="bg-body-tertiary">
      <Container id="myContainer">
        <Navbar.Toggle
          id="navbarToggler"
          aria-controls="basic-navbar-nav"
          style={{ color: "blue" }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className="navbarLink home-link" href="/">
              <FontAwesomeIcon icon={faHome} activeclassname="active" />
            </Nav.Link>
            <Nav.Link className="navbarLink about-link" href="about">
              <FontAwesomeIcon icon={faUser} />
            </Nav.Link>
            <Nav.Link className="navbarLink resume-link" href="resume">
              <FontAwesomeIcon icon={faAddressCard} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
