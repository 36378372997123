import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import Layout from "./components/Layout";
import { Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import About from "./components/About";
import Blog from "./components/Blog";
import Resume from "./components/Resume";

const TRACKING_ID = "G-D5KKGGNGQS"

ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    // <div className="App">
    //   <Layout />
    // </div>
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="about" element={<About />} />
          <Route path="blog" element={<Blog />} />
          <Route path="resume" element={<Resume />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
