import "./index.css";
import ProfilePic from "../../assets/images/profile.png";
import AnimatedLetters from "../AnimatedLetters";
import { useState, useEffect } from "react";

import React from "react";

const Main = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  const nameArray = [
    "V",
    "a",
    "r",
    "u",
    "n",
    " ",
    "S",
    "e",
    "n",
    "g",
    "u",
    "t",
    "t",
    "u",
    "v",
    "a",
    "n",
    ",",
  ];
  const jobArrary = [
    "S",
    "o",
    "f",
    "t",
    "w",
    "a",
    "r",
    "e",
    " ",
    "E",
    "n",
    "g",
    "i",
    "n",
    "e",
    "e",
    "r",
  ];

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 6000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      <div className="container">
        <div id="main-section" className="row">
          <div id="intro" className="col-md-7 align-self-center text-center">
            <h1>
              <span className={`${letterClass} _11`}>H</span>
              <span className={`${letterClass} _12`}>i</span>
              <span className={`${letterClass} _13`}>,</span>
              <br />
              <span className={`${letterClass} _14`}>I</span>
              <span className={`${letterClass} _15`}>'</span>
              <span className={`${letterClass} _16`}>m&nbsp;</span>
              <AnimatedLetters
                letterClass={letterClass}
                strArray={nameArray}
                idx={17}
              />
              {/* Rotate the V Varun Senguttuvan*/}
              <br />
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArrary}
                idx={35}
              />
            </h1>
            <h2 id="subtitle">programmer | movie fanatic | civ 6 expert</h2>
          </div>
          <div id="profilePic" className="col-md-5 text-center">
            <img
              src={ProfilePic}
              alt="Varun Senguttuvan Headshot"
              width="450"
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
